(function() {
	'use strict';

	$( '.gfield.readonly input' ).attr( 'readonly', 'readonly' );

	var monthSelected, yearSelected;
	var $monthField, $dayField, $yearField;

	$( document ).bind( 'gform_post_render', function() {

	$monthField = $( '#input_1_3_1' );
	$dayField = $( '#input_1_3_2' );
	$yearField = $( '#input_1_3_3' );
	var $yearNow = (new Date()).getFullYear();
	var namedMonth = {
		'January': '1',
		'February': '2',
		'March': '3',
		'April': '4',
		'May': '5',
		'June': '6',
		'July': '7',
		'August': '8',
		'September': '9',
		'October': '10',
		'November': '11',
		'December': '12'
	};

	$monthField.empty();
	$dayField.hide();
	$dayField.find( 'option[value=1]' )
		.attr( 'selected', 'selected' );
	$yearField.empty();

	$monthField
		.append( $( '<option></option>' )
		.attr( 'value', '' )
		.text( 'Month' ) );

	$.each( namedMonth, function( key,value ) {
		$monthField
			.append( $( '<option></option>' )
			.attr( 'value', value )
			.text( key ) );
	});

	$monthField.find( 'option[value=' +  monthSelected + ']' )
		.attr( 'selected', 'selected' );

	$yearField
		.append( $( '<option></option>' )
		.attr( 'value', '' )
		.text( 'Year' ) );

	for ( var i = $yearNow - 18; i >= 1950; i-- ) {
		$yearField
			.append( $( '<option></option>' )
			.attr( 'value', i )
			.text( i ) );
	}

	$yearField.find( 'option[value=' +  yearSelected + ']' )
		.attr( 'selected', 'selected' );

	$( document ).on( 'change', $monthField.selector, function() {
		monthSelected = $( this ).find( 'option:selected' ).val();
	});

	$( document ).on( 'change', $yearField.selector, function() {
		yearSelected = $( this ).find( 'option:selected' ).val();
	});

	});

}());
